import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Footer = () => (
  <footer className="content background">
    {/*<a
      className="mx-2 mt-lg-0 mt-lg-2"
      href="https://skepticalinquirer.org/csicon-2022-registration/"
    >
      <button className="btn green-button footer">Register</button>
    </a>
<hr /> */}
    <div className="row logo-row no-stretch">
      <a href="https://centerforinquiry.org/">
        <StaticImage
          src="../images/CSICON-logo-mobile.png"
          className="footer-logo"
          imgStyle={{ padding: "20px" }}
          objectFit="contain"
          placeholder="tracedSVG"
          width="250px"
          loading="lazy"
        />
      </a>
      <a href="https://skepticalinquirer.org/">
        <StaticImage
          src="../images/SI-logo-tag-line.png"
          className="footer-logo"
          imgStyle={{ padding: "20px" }}
          objectFit="contain"
          placeholder="tracedSVG"
          width="250px"
          loading="lazy"
        />
      </a>
    </div>
  </footer>
)

export default Footer
