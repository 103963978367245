import * as React from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { StaticImage } from "gatsby-plugin-image"

const Header = () => (
  <nav className="navbar navbar-expand-lg">
    <a className="navbar-brand" href="/#home">
      {" "}
      <StaticImage
        id="logo"
        className="d-none d-md-block"
        src="../images/CSICON-logo-standard.png"
        style={{ width: "255px" }}
        objectFit="contain"
        placeholder="tracedSVG"
        loading="eager"
      />
      <StaticImage
        id="logo"
        className="d-md-none d-block"
        src="../images/CSICON-logo-mobile.png"
        width="65px"
        style={{ height: "50px" }}
        objectFit="contain"
        placeholder="tracedSVG"
        loading="eager"
      />
    </a>
    <div
      id="menu-text"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      MENU
    </div>
    <div
      id="nav-icon3"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>

    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ml-lg-auto">
        <li className="nav-item mx-auto mr-lg-2">
          <Link to="/speakers/">Speakers</Link>
        </li>
        <li className="nav-item mx-auto x-lg-2">
          <AnchorLink to="/#schedule" title="Schedule"></AnchorLink>
        </li>
        <li className="nav-item mx-auto mx-lg-2">
          <AnchorLink to="/#info">Hotel Info</AnchorLink>
        </li>
        <li className="nav-item mx-auto mr-lg-2">
          <Link to="/videos/">Interviews</Link>
        </li>
        <li className="nav-item mx-auto mr-lg-2">
          <Link to="/conference-map/">Conference Map</Link>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdownMenuLink"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Other Info
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li>
              <AnchorLink
                to="/#call-papers"
                title="Call for Papers"
                className="dropdown-item"
              />
            </li>
            <li>
              <AnchorLink
                to="/#volunteer"
                title="Volunteer Information"
                className="dropdown-item"
              />
            </li>

            <li>
              <AnchorLink
                to="/#help-studnets"
                title="Donate for Students"
                className="dropdown-item"
              />
            </li>
          </ul>
        </li>

        {/* <li className="nav-item mx-auto ml-lg-2">
          <a
            className="btn white-button-outline"
            href="https://skepticalinquirer.org/csicon-2022-registration/"
          >
            Register
          </a>
</li>*/}
      </ul>
    </div>
  </nav>
)

export default Header
